<template>
<div id="promo-logo">
  <img src="../assets/img/brand/logo.png">
  <div class="row">
  <span>Legal Matter Management Software</span>
  </div>
</div>
</template>

<script>

export default {
  name: 'PromoLanding',
}
</script>

<style scoped>

#promo-logo{
  position: absolute;
  /* right: 50%; */
  /* left: 40%; */
  top: 40%;
  width: 100%;

}
img {
  width: 300px;
}
</style>


